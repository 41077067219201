@media (max-width: 991.98px) {


    .mobile-footer {
        img {
            max-width: 80vw;
        }
    }
    .header-item {
        background: white;
        border: 2px solid #e4e4e4;
        padding: 10px;
    }

    .page-item .black-box {
        height: auto !important;
    }

    .row > .col-6 img {
        height: 430px;
        object-fit: cover;
    }

    .page-content-wrapper {
        width: 100% !important;
        margin-left: 0 !important;


        p.raw-text {
            font-family: Playfair Display,serif;
            font-size: 18px;
            text-align: left;
            padding-left: 10px;
            padding-right: 10px;

        }
    }


    div.divider {
        background-position: center center;
        background-size: contain;
        width: 100%;
        max-width: unset !important;
        height: 20px;
    }

    .border-div {
        text-align: center;
        position: absolute;
        top: 100px;
        margin: auto;
        background: white;
        padding: 10px;
        left: 0;
        right: 0;
        width: 80%;
        border: 2px solid #e4e4e4;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 1;
    }

    .for-mobile {
        .contact-content {
            width: 80%;
            margin: auto;
            padding-top: 100px;
            padding-bottom: 100px;
        }

        .contact-content .big-text {
            font-size: 12vw;
            text-align: left;
        }

        .contact-content .form-group {
            margin-bottom: 3rem;
        }

        .form-control {
            display: block;
            width: 100%;
            font-family: Nunito, sans-serif;
            height: calc(1.6em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: .9rem;
            font-weight: 400;
            line-height: 1.6;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: none;
            border-bottom: 1px solid #ced4da;
            border-radius: 0;
            -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }

        .footer-icons {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
        }

        .mobile-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 40px;
        }

        .mobile-footer .footer-icons a img {
            height: 60px;
        }

        .mobile-footer {
            margin-bottom: 20px;
        }

        .mobile-footer .tel {
            font-size: 30px;
            color: #000;
            text-decoration: none;
            font-family: Nunito, sans-serif;
        }

        .contact-content .btn-black {
            width: 100%;
            padding: 14px;
            border-radius: 0;
            font-family: Nunito, sans-serif;
            font-size: 8vw;
        }
    }

    .home-page.home-page-mobile {

        .col-holder.active {
            margin-left: 0%;
        }

        .col-holder .home-col {
            height: auto !important;
        }

    }

    .white-bg {
        background: white !important;
        z-index: 9999999;
        position: relative;
    }

    .big-text {
        font-size: 6vw;
    }

    .top-contact-container.active {
        width: 100%;
    }

    #mobileMenu.mobile-menu {
        width: 100%;
        height: 0;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        overflow: hidden;
        background: #fff;
        transition: all .4s ease;
        align-items: center;
        z-index: 5000;

        &.active {
            height: 100%;
        }
    }

    .mobile-menu .mobile-menu-panel-item {
        font-family: Playfair Display,serif;
        font-size: 28px;
        color: #318c41;
        border-left: 4px solid #318c41;
        transition: all .4s ease;
        cursor: pointer;
    }
    .mobile-menu .mobile-menu-panel-item.active {
        padding-left: 15px;
        opacity: 1;
    }

    .mobile-header, .mobile-header .header-box {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 50000;
    }

    .mobile-header .header-box {
        width: 33.33333%;
    }

    .mobile-header .header-box a, .mobile-header .header-box i {
        color: #318c41;
    }
    .contact-button {
        cursor: pointer;
    }

}
