@import "~react-image-gallery/styles/css/image-gallery.css";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
.main-page {
    height: 100vh;
}

.home-page {

    // for mobile
    &.home-page-mobile {
        .col-holder {
            display: flex;
            flex-wrap: wrap;
            transition: .4s ease-in-out;


            &.active {
                height: 100%;
            }
        }

        .mobile-logo {
            margin-left: auto;
            margin-right: auto;
            width: 50%;
        }

        .page-key-3 {
            margin-left: 0 !important;
        }

        .main-menu-mobile {

        }

        .col-holder .home-col {
            //background: url(/about-renew-duo.jpg?9a7cd42…);
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .4s ease-in-out;
            height: 25vh;
            width: 50%;
            min-height: 200px;
            overflow: hidden;

            &.h-0 {
                height: 0 !important;
                min-height: 0 !important;
                width: 0 !important;
                opacity: 0 !important;
            }

            &.leaver {
                opacity: 0;
                transform: translateX(-200%) !important;
            }
        }

        .mobile-menu {
            width: 100%;
            height: 0;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            overflow: hidden;
            background: #fff;
            transition: all .4s ease;
            align-items: center;
            z-index: 5000;
        }

        .mobile-menu, .mobile-menu .panel-div {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }

    .menu-panel {
        width: 0 !important;
        background: #fff;
        height: 100vh;
        position: fixed;
        z-index: 500;
        top: 0;
        transition: all .4s ease-in-out;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        &.active {
            width: 40% !important;
        }

        &.left {
            //left: 60%;
            transition: all .4s ease-in-out;
            right: 60%;
            align-items: flex-end;
        }

        &.right {
            left: 60%;
        }
    }

    .col-holder {
        display: flex;
        transition: .4s ease-in-out;

        &.active {
            margin-left: 20%;
        }

        .home-col {
            height: 100vh;
            width: 20%;
            background: red;

            &.w-0 {
                width: 0 !important;
                margin-left: 0 !important;
            }
        }
    }

    .page-key-3 {
        margin-left: 20%;
    }

    .main-menu {
        background: #fff !important;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        transition: .4s ease-in-out;
        position: fixed;
        margin-left: 40%;
        left: 0;
        right: 0;

        &.active {
            margin-left: 0;
            border-right: 2px solid #e4e4e4;
            z-index: 5566;
        }
    }

    .col-holder .home-col.w-100 {
        margin-left: 0 !important;
    }

    .col-holder .home-col {
        cursor: pointer;
        background: url('./assets/a1_2.jpg') no-repeat center;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        border-left: 2px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .4s ease-in-out;


        &.active {
            cursor: default;
        }
        &:hover, &.active {
            background-image: url('./assets/a1_1.jpg');
        }

        &:hover, &.active {
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
        }


        &.page-key-2 {
            background-image: url('./assets/a2.jpg');

            &:hover, &.active {
                background-image: url('./assets/a2_1.jpg');
            }
        }

        &.page-key-3 {
            background-image: url('./assets/a3.jpg');

            &:hover, &.active {
                background-image: url('./assets/a3_1.jpg');
            }
        }

        &.page-key-4 {
            background-image: url('./assets/a4.jpg');

            &:hover, &.active {
                background-image: url('./assets/a4_1.jpg');
            }
        }

    }



}


.content-area {
    width: 100%;
    position: relative;
    min-height: 500px;

    .all-lines {
        justify-content: space-evenly;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        .line {
            width: 2px;
            background: #e4e4e4;
            height: 100%;
            display: inline-block;
        }
    }
}

.footer {
    align-items: center;
    justify-content: center;
    //height: 70px;
    border: 2px solid #e4e4e4;

    font-size: 14px !important;


    > div {
        border: none;
        height: unset;
        color: #318c41;
    }

    span {
        cursor: pointer;
        font-family: Playfair Display,serif;
        color: #318c41;
        margin: 5px;
    }
}
.no-border {
    border: none !important;
}
.top-contact-container {
    width: 0;
    position: fixed;
    right: 0;
    z-index: 5000000;
    min-height: 100vh;
    background: #fff;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
    transition: all .4s ease;
    height: 100%;

    &.active {
        width: 80%;
    }

    .contact-flex {
        width: 100%;
        right: 0;
        z-index: 5000000;
        height: 100vh;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        transition: all .4s ease;
    }

    .lines {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .lines .line {
        width: 2px;
        background: #e4e4e4;
        height: 100%;
    }

    .contact-close-button {
        background: #318c41;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-transition: .4s ease-in-out;
        transition: .4s ease-in-out;
        opacity: 1;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        font-size: 14px;
    }

    .contact-form {
        width: 80%;
        padding: 50px;
        font-size: 14px !important;
        position: absolute;
        right: 10%;
        border: 2px solid #318c41;
        margin-top: unset;
        background: #fff;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        height: 500px;
        z-index: 600000000000;
        font-family: Nunito, sans-serif;
        border-radius: 11px;
        box-shadow: 1px 4px 4px 1px rgba(0,0,0,5%);
    }

    .contact-form .contact-side-image {
        // background: url(https://renewlandscapes.com/contact-us.jpg);
        width: 20%;
        height: 100%;
        background-size: cover;
        position: absolute;
        left: 0;
       
    }
    .contact-form .contact-side-img {
        // background: url(https://renewlandscapes.com/contact-us.jpg);
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        height: 100%;
        width: 100%;
       
    }

    .contact-form .contact-content {
        width: 80%;
        margin-left: 20%;
    }

    .contact-form .form-control {
        display: block;
        width: 100%;
        height: calc(1.6em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: .9rem;
        font-weight: 400;
        line-height: 1.6;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0;
        -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
}
.btn-black {
    background: #000;
    color: #fff;
    opacity: 1;
    font-size: 14px;
    &:hover {
        background: #000;
        color: #fff;
    }
}

.menu-panel.left .lines {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.menu-panel.left .lines .line {
    width: 2px;
    background: #e4e4e4;
    height: 100%;
}

.menu-panel.right .lines {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.menu-panel.right .lines .line {
    width: 2px;
    background: #e4e4e4;
    height: 100%;
}

.ms-50-percents {
    margin-left: 50%;
}
.me-50-percents {
    margin-right: 50%;
}

.menu-panel-item {
    text-decoration: none;
}
.menu-panel.left .menu-panel-item {
    padding-right: 15px;
    opacity: 1;
}
.menu-panel.right .menu-panel-item {
    padding-left: 15px;
    opacity: 1;
}
.menu-panel.left .menu-panel-item {
    font-family: Playfair Display,serif;
    font-size: 28px;
    color: #318c41;
    border-left: none;
    border-right: 4px solid #318c41;
    transition: all .4s ease;
    cursor: pointer;
    z-index: 50;
}
.menu-panel.right .menu-panel-item {
     font-family: Playfair Display,serif;
     font-size: 28px;
     color: #318c41;
     border-left: 4px solid #318c41;
     transition: all .4s ease;
     cursor: pointer;
     z-index: 50;
 }
