.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  overflow-x: hidden !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.header-item {
  margin-top: 15px;
  margin-bottom: 15px;
}

.raw-text {
  font-family: Nunito,sans-serif;
  font-size: 14px;
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.green-text {
  font-family: Playfair Display,serif;
  color: #318c41;
  font-size: 1.2em;
}

.home-page .green {
  color: #318c41;
}

.no-text-decor {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer !important;
}

.yarl__portal_open {
  opacity: 1;
  z-index: 99999999 !important;
}

.yarl__button.yarl__navigation_next,
.yarl__button.yarl__navigation_prev
{
  display: none !important;
}

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 7px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #318c41; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4eb361; 
}
.design-li {
  font-size: 0.7em;
  color:#00ff2fcc;
  
}
.img-gallery { 
  border-radius: 20px;
  border:3px white solid;
  width: 80%;
  height: 500px;
  box-shadow: 1px 4px 4px 1px rgba(0,0,0,14%);
  cursor: pointer;
  display: block;
  transition: 0.3s;
  
}
.img-gallery:hover {
  box-shadow: 1px 4px 4px 1px #318c41 !important;

  
}
.tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  text-align: center;
  gap:1em;
  width: 80%;
}
.single-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  height: 100%;
  font-size: 2em;
  color:white;
  font-family: cursive;
  border-radius: 15px;
  box-shadow:1px 4px 4px 1px rgba(0,0,0,5%);
  background: #318c42b1;
}

.form-control {
  font-family: cursive !important;
}

.contact-submit-btn {
  background: #318c41;
  color:white;
}
.contact-submit-btn:hover {
  background: #227231;
  color:white;
}

.services-img {
  border: 2px solid #227231;
    border-radius: 10px;
    box-shadow: 1px 4px 4px 1px rgba(0,0,0,5%);
}

@media only screen and (max-width: 600px) {
  .top-contact-container .contact-form .contact-side-image  {
    width: 100% !important;
    opacity: 0.1;
  }
  .top-contact-container .contact-form .contact-content {
    width: 100% !important;
    margin-left:0 !important;
  }
  .contact-title-screen {
    text-align: center !important;
  }
  .contact-submit-btn {
    width: 100% !important;
  }
}

.footer-btn-send {
  background: #227231 !important;
}
.design-p-height {
  font-size: 1.1vw !important;
}

@media only screen and (max-width: 821px) {

  .design-p-height {
    font-size: 20px !important;
  }

}
@media only screen and (max-width: 1367px) {

  .laptop-view-image {
    display:none !important;
   
  }
  .laptop-view-headeritem {
    flex: 0 0 auto !important;
    width: 50% !important;
  }
  .design-p-size{
    margin-left:0.5em;
  }

}

.design-p-size {
  font-size: 1.3em !important;
}







