.page-item {
    //background: url('./assets/regenerate.jpg') no-repeat;
    //background-size: cover;
    ////transition: background 1s;
    ////width: 20vw;
    //transition: all .1s ease-in-out;
    //position: relative;
    //left: 0;
    //
    //&.active-mode {
    //    right: 0;
    //    width: calc(80vw);
    //}




    &.active {
        //margin-left: 20%;
        margin-left: 0 !important;
    }

    .black-box {
        opacity: 1;
        width: 80%;
        height: 82vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 28px;
        color: #fff;
        background: rgba(0,0,0,.5);
        padding: 20px;
        transform: translateY(0);

        .black-box-inner {
            opacity: 1;
            transition: all .4s ease;
            transition-delay: .8s;
            color: #fff;


            p {
                font-family: Playfair Display,serif;
                text-align: center;
                font-size: 20px;

                max-height: 400px;
                overflow: auto;
            }
            .big-text {
                line-height: 1;
            }

            hr {
                background: url('./assets/white-divider.png');
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: contain;
                width: 100%;
                max-width: 400px;
                height: 20px;
                border-top: none;
                opacity: 1;
            }
        }

        .home-page .col-holder .home-col.clickable-col.w-100 .black-box .black-box-inner {
            opacity: 1;
            transition: all .4s ease;
            transition-delay: .8s;
            color: #fff;
        }
    }

    .col-label {
        background: rgba(0,0,0,.5);
        width: 100%;
        padding: 5px;
        font-size: 28px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .4s ease-in-out;
        opacity: 0;
        font-family: Playfair Display,serif;
    }

    &:hover {
        .col-label {
            opacity: 1 !important;
        }
    }

    &.active {
        .col-label {
            display: none;
        }
    }
}
