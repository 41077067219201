.page-content-wrapper {
    width: 80%;
    margin-left: 20%;
    //background: red;
    min-height: 500px;
    z-index: 9999 !important;
    position: relative;

    img {
        z-index: 9999;
    }

    .green-text {
        font-family: Playfair Display,serif;
        color: #318c41;
        &.big {
            font-size: 28px;
            font-weight: 700;
        }
    }

    div.divider {
        background: url('./assets/black-divider.png') no-repeat 50%;
        background-size: contain;
        width: 100%;
        //max-width: 400px;
        height: 20px;
    }
}
