.main-menu {
    //min-width: 200px;
    width: 20vw;
    //max-width: 400px;
    background: white;
    //transition: all 500ms;
    transition: left .1s ease-in-out;
    position: relative;
    left: 0;

    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    transition: .4s ease-in-out;
    position: fixed;
    margin-left: 40%;
    left: 0;
    right: 0;

    &.show-page-active {
        //position: fixed;
        left: -40vw;
        //left: 0;
        //top: 0;
        //bottom: 0;
    }



    .menu-button {
        background: #318c41;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: .4s ease-in-out;
        opacity: 1;
        cursor: pointer;

        &.active {
            opacity: 1;

            > span:first-child {
                transform: rotate(45deg) translateX(2px) translateY(1px);
            }

            > span:nth-child(2n) {
                opacity: 0;
                display: none;
            }

            > span:nth-child(3) {
                transform: rotate(-45deg) translateX(4px) translateY(-3px);
            }
        }
        > span {
            background: #fff;
            width: 75%;
            height: 1px;
            margin: 4px;
            transition: .4s ease-in-out;


        }
    }

}
